/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 */
import React, { lazy, Suspense, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import ScrollUp from "./components/ScrollUp";
import "./AgentHomePage.css";
import "@fontsource/poppins";
import "../src/App.css";
import Header from "./components/Header";
import Body from "./components/Body/Body";
import axios from "axios";

const Lodetails = lazy(() => import("./components/Lodetails/lodetails.js"));
const Lowebsites = lazy(() => import("./components/Lowebsites/index.js"));
const EventHighlights = lazy(() => import("./components/EventHighlights/index.js"));
const Presentations = lazy(() => import("./components/Presentation/presentation.js"));
const BorrowerLeadForm = lazy(() => import("./components/BorrowerLeadForm/BorrowerLeadForm.js"));
const Footer = lazy(() => import("./components/Footer/footer.js"));

function App() {
  const { agentName } = useParams();
  const agentJsonName = agentName.toLowerCase() + ".json";
  const [loading, setLoading] = useState(true);
  const [agentData, setAgentData] = useState();
  const [loanOfficerActive, setLoanOfficerActive] = useState(true);
  const [loanOfficerData, setLoanOfficerData] = useState();
  const [angelAiUserInfo, setAngelAiUserInfo] = useState();

  useEffect(() => {

    const fetchData = async () => {
      try {
        const agentResponse = await axios.get(
          `${process.env.REACT_APP_CDN_LINK}marketing-lo-website/miniexpocenter/${agentJsonName}`
        );

        const responseData = agentResponse.data;
        setAgentData(responseData);
        if (responseData) {
          setLoading(false);
          const loanOfficerId = responseData.staff.loanOfficer.lossid;
          await checkifLoanOfficerIsActive(loanOfficerId);
          await fetchAngelAiUserInfo();
          setLoanOfficerData(responseData);
        }
      } catch (error) {
        console.log(error);
      }
    };

    /**
   * Fetches the Angel AI user info from the specified endpoint.
   *
   * @return {Promise<void>} - A Promise that resolves when the user info has been fetched successfully.
   */
    const fetchAngelAiUserInfo = async () => {
      try {
        await axios
          .get(process.env.REACT_APP_ANGEL_AI_WEB_USER_INFO)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            setAngelAiUserInfo(response.data);
          })
          .catch((error) => {
            // console.log(error);
            window.location.replace("https://swmc.com");
          });
      } catch (e) {
        console.log(e);
      }
    };

    const checkifLoanOfficerIsActive = async (loanOfficerId) => {
      try {
        const loanOfficerResponse = await axios.post(
          process.env.REACT_APP_CHECK_LO_ACTIVE,
          { loanOfficerId }
        );
        setLoanOfficerActive(loanOfficerResponse.data.active);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="bodyContainer">
        {loading ? (
          <div className="mt-5 mb-3 text-center">
            <Spinner animation="border" />
            <h5>Loading...</h5>
          </div>
        ) : (
          <>
            {agentData && (
              <>
                <Suspense fallback={<div />}>
                  {!loanOfficerData ? (
                    <div className="mt-5 mb-3 text-center">
                      <Spinner animation="border" />
                      <h5>Loading...</h5>
                    </div>
                  ) : (
                    <>
                      {
                        <>
                          <ScrollUp />
                          <Header
                            loanOfficerActive={loanOfficerActive}
                            loanOfficerData={loanOfficerData}
                            angelAiUserInfo={angelAiUserInfo}
                          />
                          <Body
                            loanOfficerActive={loanOfficerActive}
                            loanOfficerData={loanOfficerData}
                            angelAiUserInfo={angelAiUserInfo}
                          />
                          <Lowebsites
                            loanOfficerActive={loanOfficerActive}
                            loanOfficerData={loanOfficerData}
                            angelAiUserInfo={angelAiUserInfo}
                          />
                          <EventHighlights
                            loanOfficerActive={loanOfficerActive}
                            loanOfficerData={loanOfficerData}
                            angelAiUserInfo={angelAiUserInfo}
                          />
                          <Presentations
                            loanOfficerActive={loanOfficerActive}
                            loanOfficerData={loanOfficerData}
                            angelAiUserInfo={angelAiUserInfo}
                          />
                          <Lodetails
                            loanOfficerActive={loanOfficerActive}
                            loanOfficerData={loanOfficerData}
                            angelAiUserInfo={angelAiUserInfo}
                          />
                          <BorrowerLeadForm
                            loanOfficerActive={loanOfficerActive}
                            loanOfficerData={loanOfficerData}
                            angelAiUserInfo={angelAiUserInfo}
                          />
                          <Footer />
                        </>
                      }
                    </>
                  )}
                </Suspense>
              </>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default App;
